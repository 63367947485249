import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
// import { Button } from 'react-bootstrap';
import myResume from './myResume.pdf';
import { aboutData } from '../../mock/data';
import './_annotationLayer.scss';
import './_resume.scss';

export default class Resume extends Component {
  constructor() {
    super();
    this.state = {
      // numPages: null,
      pageNumber: 1,
    };

    // const onDocumentLoadSuccess = ({ numPages }) => {
    //   this.setState({ numPages });
    // };
  }

  render() {
    const { pageNumber } = this.state;

    return (
      <div>
        <span className="resume">
          <div>
            <a rel="noopener noreferrer" href="/" className="cta-btn-resume cta-btn--hero">
              Go Back
            </a>
            <a
              rel="noopener noreferrer"
              href={aboutData.resume}
              className="cta-btn-resume cta-btn--hero"
            >
              Download
            </a>
          </div>
        </span>
        <div className="resume">
          <Document file={myResume} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      </div>
    );
  }
}
